
import React from 'react'
import Sliderone from '../Menhome/Sliderone'
import Loans from '../Menhome/Loans';
import Offering from '../Menhome/Offering';
import Flexible from '../Menhome/Flexible';
import Trusted from '../Menhome/Trusted';
import Counter from '../Menhome/Counter';
import Testimonials from '../Menhome/Testimonials';
import Why from '../Menhome/Why';
import Clint from '../Menhome/Clint';
import Quickly from '../Menhome/Quickly';

const Main = () => {
   
  return (
    <>
        <Sliderone/>
        <Loans/>
        <Offering/>
        <Flexible/>
        <Trusted/>
        <Clint/>
        <Testimonials/>
        <Why/>
        <Quickly/>
    </>
  )
}

export default Main