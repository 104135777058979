import React from 'react'
import { Link}  from 'react-router-dom'
import ApplicationForm from '../hubspotcomponent'

function Leave() {
    return (
        <>
            <section className="contact-one">
                <div className="container">
                    <div className="block-title text-center">
                        <p>Connect With Us</p>
                        <h2>What's Your Dream?</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contact-one__content">
                                <p>Reach out to our team of Finance Professionals today and see how we can help.</p>
                                <div className="contact-one__box">
                                    <i className="pylon-icon-telephone"></i>
                                    <div className="contact-one__box-content">
                                        <h4>Call Anytime</h4>
                                        <Link to="tel:+61425279148">0425 279 148</Link>
                                    </div>
                                </div>
                                <div className="contact-one__box">
                                    <i className="pylon-icon-email1"></i>
                                    <div className="contact-one__box-content">
                                        <h4>Write Email</h4>
                                        <Link to="mailto:admin@eliteassetfinance.com.au">admin@eliteassetfinance.com.au</Link>
                                    </div>
                                </div>
                                <div className="contact-one__box">
                                    <i className="pylon-icon-pin1"></i>
                                    <div className="contact-one__box-content">
                                        <h4>Service Areas</h4>
                                        <Link to="#">Throuhgout Australia</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <ApplicationForm 
                                region="eu1"
                                portalId="143255438"
                                formId='9abe8139-83f9-4e17-80e4-05391c4de241'
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Leave