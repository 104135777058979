import SSBAR1 from '../../../assets/images/services/service-sidebar-1-1.jpg'
import { Link }  from 'react-router-dom'
import React, { useState } from 'react'

function First() {
    const[activeFaq, setActiveFaq] = useState({b: true})
    return (
        <>
            <section className="service-details faq-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details__content">
                                
                                <h3>Vehicle, Marine, Equipment and Personal Finance</h3>
                                <p>In this competitive market, Elite Asset Finance can offer a unique competitive edge to its competitors. With our extensive experience and expertise in the finance industry, Elite Asset Finance is able to provide tailored solutions that meet the every-day needs of individuals of all kinds. With an extensive network of over 30 lenders, we offer competitive interest rates and favorable terms to all of our clients. Additionally, Elite Asset Finance understands the importance of efficiency in the loan process, ensuring quick loan approvals and a seamless experience. By leveraging their knowledge, relationships, and customer-centric approach, Elite Asset Finance can provide individuals and families with a distinct advantage in accessing asset finance loans in Australia.</p>
                                <div className="service-details__image">
                                    <img src="assets/images/services/mazda-on-windy-road.jpg" className="img-fluid" alt="" />
                                </div>
                                <p>Our streamlined loan process ensures hassle-free applications and quick approvals, allowing individuals to acquire the assets they need without delays. Furthermore, Elite Asset Finance offers tailored solutions, allowing borrowers to customise their repayment plans according to their budget and cash flow requirements. With Elite Asset Finance, everyday Australians can enjoy the convenience and ease of accessing finance for a wide range of assets, whether it be a new car, boat, equipment, technology, or other essential items needed for personal or business purposes.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <img src="assets/images/services/service-d-1-2.jpg" alt="" className="service-details__content-image img-fluid" />
                                    </div>
                                    <div className="col-xl-6">
                                        <h4>Vehicle Finance</h4>
                                        <p>Whether you're upgrading or simply in the market for a new/used vehicle, boat or mortocycle, Elite Asset Finance is determined to bring your dream directly to your driveway!</p>
                                        <ul className="list-unstyled about-two__list service-details__list">
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                Highly Competitive Interest Rates
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                Flexible and Customisable Repayments
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                Vehicles up to $250,000!
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                Financing approved in as little as 24 business hours.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    
                                    <div className="col-xl-6">
                                        <h4>Equipment & Personal Finance</h4>
                                        <p>From commercial machenery to everyday office quipment, Elite Asset Finance is dedicated to fulfilling your next big step.</p>
                                        <ul className="list-unstyled about-two__list service-details__list">
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                30+ Approved & Acredited Lenders
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                Streamlined Application Processes
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                No Credit Discimination
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                Up to $1,000,000
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-6">
                                        <img src="assets/images/services/service-equipment.jpg" alt="" className="service-details__content-image_right img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First