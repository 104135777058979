
import ActionBG1 from '../../assets/images/backgrounds/call-to-action-bg-1-1.jpg'
import { Link }  from 'react-router-dom'
import React from 'react'

function Quickly() {
  return (
    <>
         <section className="call-to-action" style={{ backgroundImage: `url(${ActionBG1})`}}>
            <div className="container">
                <div className="left-content">
                    <p><span>Simple</span><span>Transparent</span><span>Secure</span></p>
                    <h3>Obtain Asset Financing the Elite way!</h3>
                </div>
                <div className="right-content">
                    <Link to="/contact" className="thm-btn">Get Started Today!</Link>
                </div>
            </div>
        </section>
    </>
  )
}

export default Quickly