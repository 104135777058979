import React from 'react'
import Bradcom from '../../components/Bradcom/Main'
import First from '../Menterms/First'

const Application = () => {
  return (
    <>
        <Bradcom title={"Home"} subtitle={"Terms"} common={"Terms & Conditions"}/>
        <First/>
    </>
  )
}

export default Application