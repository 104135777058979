import React from 'react'
import { Link}  from 'react-router-dom'

function First() {
    return (
        <>
            <section className="team-details">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                            <div className="block-title text-left">
                                <h4>Elite Asset Finance Sitemap</h4>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="block-text"><a href='/'>Home</a><br></br><a href='/application'>Application</a><br></br><a href='/services'>Services</a><br></br><a href='/finance-options'>Finance Options</a></p>
                        </div>
                        <div className="col-lg-6">
                            <p className="block-text"><a href='/about-us'>About Us</a><br></br><a href='/loan-calculator'>Loan Calculator</a><br></br><a href='/vehicle-sales'>Sell Your Vehicle</a><br></br><a href='/contact'>Contact Us</a></p>
                        </div>
                    </div>
                
                </div>
            </section>
        </>
    )
}

export default First