import { Link }  from 'react-router-dom'
import { Autoplay , Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';

function Testimonials() {
    return (
        <>
            <section className="testimonials-one">
                <div className="container">
                <div className="row">
                        <div className="col-lg-6">
                            <div className="block-title text-left">
                                <p>Elite Asset Finance is Awesome!</p>
                                <h2>But Don't Just Take Our Word For It.</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="block-text">Read some of the below customer reviews directly from our Google Reviews!</p><br />
                            <a href="https://www.google.com/search?q=elite+asset+finance&rlz=1C1ONGR_enAU1058AU1058&oq=elite+asset+finance&aqs=chrome.0.69i59j69i57j69i59j69i60l3.1978j0j4&sourceid=chrome&ie=UTF-8#lrd=0xa4de70069c676e11:0x788dc874fb87e96,1,,,," target='_blank' className='thm-btn text-left'>Take a look at all of our reviews now on Google!</a>
                        </div>
                    </div>
                    <Swiper className="thm-swiper__slider swiper-container"
                        modules={[Pagination, Autoplay]}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{ delay: 5000 }}
                        pagination={{
                            el: '.testimonials-one__swiper-pagination',
                            type: 'bullets',
                            clickable: true

                        }}
                        breakpoints={{
                            "376": {
                                "spaceBetween": 30,
                                "slidesPerView": 1,
                                "slidesPerGroup": 1
                            },
                            "576": {
                                "spaceBetween": 30,
                                "slidesPerView": 1,
                                "slidesPerGroup": 1
                            },
                            "768": {
                                "spaceBetween": 30,
                                "slidesPerView": 2,
                                "slidesPerGroup": 2
                            },
                            "992": {
                                "spaceBetween": 30,
                                "slidesPerView": 3,
                                "slidesPerGroup": 3
                            },
                            "1200": {
                                "spaceBetween": 30,
                                "slidesPerView": 3,
                                "slidesPerGroup": 3
                            }}}>
                        <div className="swiper-wrapper">
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonials-one__box">
                                        <p><span>Every question / query I had he always had an answer or quickly tracked down the information I needed. He went beyond securing a loan but also gave me piece of mind and a few helpful bits of advice. Can not recommend highly enough.</span></p>
                                        <div className="testimonials-one__box-info">
                                            <img src="assets/images/resources/lance-gunn.png" alt="" /><br />
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <h3>Lance Gunn</h3>
                                            <span>29 Aug. 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonials-one__box">
                                        <p><span>Brian and Russell were really prompt and helpful in every step of the way. Again fellas thank you so very much for making our financial needs in a timely manner and really really appreciate all the help. Keep it up.! Thank you. 👌👍</span></p>
                                        <div className="testimonials-one__box-info">
                                            <img src="assets/images/resources/harold-martin.png" alt="" /><br />
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <h3>Harold Martin</h3>
                                            <span>23 Aug. 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonials-one__box">
                                        <p><span>Brian did an amazing job with finding me a new refinancing company to take on the loan of my new car. Great customer service and goes one step further in every aspect.</span></p>
                                        <div className="testimonials-one__box-info">
                                            <img src="assets/images/resources/emma-larson.png" alt="" /><br />
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star"></i>
                                            <h3>Emma Larson</h3>
                                            <span>16 Aug. 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonials-one__box">
                                        <p><span>Brian was very efficient and got my loan sorted promptly. Great communication and follow up process. Would highly recommend Elite Asset Finance to anyone that's needing a loan of any sort .</span></p>
                                        <div className="testimonials-one__box-info">
                                            <img src="assets/images/resources/fatima-edward.png" alt="" /><br />
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <h3>Fatima Edward</h3>
                                            <span>12 Aug. 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonials-one__box">
                                        <p><span>Best experience, helped me with a lot and was patient with me and talked me through everything made sure I understood everything. Not like other finances in the past who have just given up on me and couldn’t be bothered to help me. 100% would strongly agree choosing Elite Asset Finance. Very friendly and understanding :)</span></p>
                                        <div className="testimonials-one__box-info">
                                            <img src="assets/images/resources/noelleen-fraser.png" alt="" /><br />
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <h3>Noelleen Fraser</h3>
                                            <span>30 Aug. 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonials-one__box">
                                        <p><span>10/10 service! Would highly recommend the boys at Elite asset finance if you are wanting to finance a new car. They made the process super easy through their broad knowledge and therefore was able to suggest the best plan tailored to myself 👏🏽👏🏽👏🏽</span></p>
                                        <div className="testimonials-one__box-info">
                                            <img src="assets/images/resources/ethan-lemons.png" alt="" /><br />
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <h3>Ethan Lemons</h3>
                                            <span>9 Aug. 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonials-one__box">
                                        <p><span>Best car finance experience I have ever had, Brian worked hard finding me a great deal and kept me updated through the process, if you are looking for a honest, professional, informative service for you're next loan, look no further.</span></p>
                                        <div className="testimonials-one__box-info">
                                            <img src="assets/images/resources/davidl.png" alt="" /><br />
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <h3>David Luggett</h3>
                                            <span>12 Sept. 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonials-one__box">
                                        <p><span>Had a great experience with elite asset finance, made borrowing a loan very easy, excellent service from start to finish. Would highly recommend their service :)</span></p>
                                        <div className="testimonials-one__box-info">
                                            <img src="assets/images/resources/gloriaf.png" alt="" /><br />
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <h3>Gloria Francois</h3>
                                            <span>12 Sept. 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </div>

                        <div className="testimonials-one__swiper-pagination swiper-pagination"></div>
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Testimonials