import React from 'react'

function Flexible() {
    const features = [
        {
            icon: "pylon-icon-assets",
            text: "Flexible & Competitive Interest Rates"
        },
        {
            icon: "pylon-icon-verification",
            text: "Guaranteed Compliance & Support"
        },
        {
            icon: "pylon-icon-finance",
            text: "15+ Years of Finance Experience & Knowledge"
        }
    ];

    return (
        <>
            <section className="feature-one">
                <img src="assets/images/shapes/feature-shape-1-1.png" alt="" className="feature-one__shape-1" />
                <img src="assets/images/shapes/feature-shape-1-2.png" alt="" className="feature-one__shape-2" />
                <img src="assets/images/shapes/feature-shape-1-3.png" alt="" className="feature-one__shape-3" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="block-title text-left">
                                <p>Get to Know Our Financing</p>
                                <h2>Flexible and Quick Financing</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="block-text">With competitive interest rates, flexible repayment options, and easy application processes, Elite Asset Finance is your number one choice for car, boat, and personal loan solutions.</p>
                        </div>
                    </div>
                    <div className="row">
                        {features.map((feature, index) => (
                            <div className="col-lg-4" key={index}>
                                <div className="feature-one__box">
                                    <i className={feature.icon}></i>
                                    <p>{feature.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Flexible
