import React from 'react'
import { Link } from 'react-router-dom'

function Transparent() {
    return (
        <>
            <section className="about-two">                
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-two__content">
                                <div className="block-title text-left">
                                    <p>Company Introductions</p>
                                    <h2>We are <span>Elite Asset Finance</span>!</h2>
                                </div>
                                <p>With a combined 20+ years of experience in the finance industry, the Elite Asset Finance offers an effective, efficient and affordable approach to a wide range of Asset Financing opportunies including, Car, Boat, Equipment and Personal Loan financing along with Vehicle Purchases.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Transparent