import React from 'react'
import Bradcom from '../../Bradcom/Main'
import First from '../../Menteam/First'

const Team = () => {
  return (
    <>
        <Bradcom title={"Home"} subtitle={"Team Members"} common={"Team Members"}/>
        <First/>
    </>
  )
}

export default Team