import React from 'react'
import Bradcom from '../../components/Bradcom/Main'
import First from '../Mensitemap/First'

const Application = () => {
  return (
    <>
        <Bradcom title={"Home"} subtitle={"Sitemap"} common={"Sitemap"}/>
        <First/>
    </>
  )
}

export default Application