import React from 'react'
import Bradcom from '../../components/Bradcom/Main'
import First from '../Mendisclaimer/First'

const Application = () => {
  return (
    <>
        <Bradcom title={"Home"} subtitle={"Disclaimer"} common={"Disclaimer"}/>
        <First/>
    </>
  )
}

export default Application