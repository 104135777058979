import { Link}  from 'react-router-dom'
import React from 'react'

const Footer = () => {
  return (
    <>
        <footer  className="site-footer">
            <div  className="container">
                <div  className="row">
                    <div  className="col-lg-2 col-md-6">
                        <div  className="footer-widget footer-widget__about">
                            < Link to="/">
                                <img src="assets/images/logo-light.png" width="150" alt=""/>
                            </Link>
                            <p></p>
                            <div  className="footer-widget__about-phone">
                                <i  className="pylon-icon-tech-support"></i>
                                <div  className="footer-widget__about-phone-content">
                                    <span>Call Anytime</span>
                                    <h3>< Link to="tel:+61425279148">0425 279 148</Link></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-3 col-md-6">
                        <div  className="footer-widget footer-widget__link">
                            <h3  className="footer-widget__title">Company</h3>
                            <ul  className="list-unstyled footer-widget__link-list">
                                <li>< Link to="/about-us">About</Link></li>
                                <li>< Link to="/service">Our Services</Link></li>
                                <li>< Link to="/loan-calculator">Loan Calculator</Link></li>
                                <li>< Link to="/application">Application</Link></li>
                                <li>< Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div  className="col-lg-3 col-md-6">
                        <div  className="footer-widget footer-widget__link">
                            <h3  className="footer-widget__title">Resources</h3>
                            <ul  className="list-unstyled footer-widget__link-list">
                                <li>< Link to="/terms-conditions">Terms & Conditions</Link></li>
                                <li>< Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li>< Link to="/disclaimer">Disclaimer</Link></li>
                                <li>< Link to="/sitemap">Sitemap</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div  className="col-lg-4 col-md-6">
                        <div  className="footer-widget footer-widget__contact">
                            <h3>Contact</h3>
                            <ul  className="list-unstyled footer-widget__contact-list">
                                <li>
                                    <a href='mailto:admin@eliteassetfinance.com.au'><i  className="pylon-icon-email1"></i>admin@eliteassetfinance.com.au</a>
                                </li>
                                <li>
                                    < Link to=""><i  className="pylon-icon-clock2"></i>Mon - Sat 9:00 AM - 6:00 PM</Link>
                                </li>
                                <li>
                                    < Link to=""><i  className="pylon-icon-pin1"></i>Servicing throuhgout Australia</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div  className="bottom-footer_disclaimer">
            <div  className="container" style={{ whiteSpace: 'pre-line' }}>
                ©2023 Francois's Financing Solutions Pty Ltd trading as <b>Elite Asset Finance</b> (ABN 72 636 763 418).<br />
                Authorised Credit Representative #521178 of Fintelligence Pty Ltd (Australian Credit Licence #511803)<br />
                <a href="https://cdn.eliteassetfinance.com.au/EliteAssetFinance-Credit-Guide-2024.pdf" target='_blank'>Credit Guide</a>. Fintelligence's <a href="https://fintelligence.com.au/privacy-policy/" target="_blank">Privacy Policy</a>. & <a href="https://fintelligence.com.au/wp-content/uploads/2020/11/Complaint-Handling-Policy-Fintelligence_24112020.pdf" target='_blank'>Complaints Policy</a>. Website by <a href='https://aydendigital.com' target='_blank'>Ayden Digital</a>.
            </div>
        </div>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
    
    </>
  )
}

export default Footer