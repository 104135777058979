import React from 'react'
import Bradcom from '../../Bradcom/Main'
import First from '../../Menteamdeatils/First'
import Quickly from '../../Menhome/Quickly'
import Transparent from '../../Mehhometwo/Transparent'
import Clint from '../../Menhome/Clint'
import {Helmet} from "react-helmet";
 
class Application extends React.Component {
  render () {
    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Title</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
        </div>
    );
  }
};

const TeamDetails = () => {
  return (
    <>
        <Bradcom title={"Home"} subtitle={"About Us"} common={"About Us"}/>
        <Transparent/>
        <Clint/>
        <First/>
        <Quickly/>
    </>
  )
}

export default TeamDetails