import React from 'react'

function Map() {
    return (
        <>
            <div className="google-map__home-two">
                <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27655831.935435046!2d136.1073692!3d-32.205415!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa4de70069c676e11%3A0x788dc874fb87e96!2sElite%20Asset%20Finance!5e0!3m2!1sen!2sau!4v1693485319666!5m2!1sen!2sau" className="map__home-two" allowFullscreen></iframe>
            </div>
        </>
    )
}

export default Map