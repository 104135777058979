import { Link}  from 'react-router-dom'
import React from 'react'
import Bredcom from '../../Bradcom/Main'
import Offering from '../../Menhome/Offering'
import Quickly from '../../Menhome/Quickly'
import Flexible from '../../Menhome/Flexible'

const Service = () => {
  return (
    <>
        <Bredcom title={"Home"} subtitle={"Service"} common={"Services"}/>
        <Offering/>
        <Flexible/>
        <Quickly/>
    </>
  )
}

export default Service