import React from 'react'
import { Link } from 'react-router-dom'

function Offering() {
    const services = [
        {
            image: "assets/images/services/services-1-1.jpg",
            title: "Vehicle Finance",
            link: "/finance-options",
            description: "Offering competitive car, motorcycle and truck finance options to suit business and personal uses with fast approval times and dedicated support."
        },
        {
            image: "assets/images/resources/marine.jpg",
            title: "Marine Finance",
            link: "/finance-options",
            description: "Obtaining finance for a new or second-hand boat, yacht and/or jetski has never been easier! Elite Asset Finance offers flexible and affordable marine financing!"
        },
        {
            image: "assets/images/services/services-1-2.jpg",
            title: "Equipment Finance",
            link: "/finance-options",
            description: "From agri-business equipment to complete industrial machines, Elite Asset Finance can help you get the loan you need to get moving!"
        }
    ];

    return (
        <>
            <section className="service-one">
                <div className="container">
                    <div className="block-title text-center">
                        <p>Elite Asset Finance Services</p>
                        <h2>Our Financing Options</h2>
                    </div>
                    <div className="row">
                        {services.map(service => (
                            <div className="col-lg-4">
                                <div className="service-one__card">
                                    <div className="service-one__image">
                                        <img src={service.image} alt="" />
                                    </div>
                                    <div className="service-one__content">
                                        <h3><Link to={service.link}>{service.title}</Link></h3>
                                        <p>{service.description}</p>
                                        <Link to={service.link} className="pylon-icon-right-arrow service-one__link"></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
                <div className="container">
                    <div className="block-title text-center">
                    </div>
                    <div className="row">
                        <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="service-one__card">
                                <div className="service-one__image">
                                    <img src="assets/images/resources/wedding.webp" alt="" />
                                </div>
                                <div className="service-one__content">
                                    <h3><Link to="/finance-options">Personal Loan</Link></h3>
                                    <p>Debt consolidation or perhaps the wedding of your dreams and a holiday? We have the cheapest options for your personal needs without having to break the bank.</p>
                                    <Link to="/finance-options" className="pylon-icon-right-arrow service-one__link"></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                            <div className="service-one__card">
                                <div className="service-one__image">
                                    <img src="assets/images/resources/selling-your-car.webp" alt="" />
                                </div>
                                <div className="service-one__content">
                                    <h3><Link to="vehicle-sales">Sell Your Vehicle</Link></h3>
                                    <p>Sell your vehicle to Elite Asset Finance! Offering competitive purchase pricing, couple with an effortless and completely digital purchase process!</p>
                                    <Link to="/vehicle-sales" className="pylon-icon-right-arrow service-one__link"></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="service-one__card">
                                <div className="service-one__final">
                                    <p>Offering a wide range of bespoke financial and purchasing options, Elite Asset Finance is committed to providing exceptional customer service and genuine, unparralled guidance to our over 300, past and present clients.<br /><br />Offering a One-Stop Shop approach to financing, Elite Asset Finance has the experience and "know-how" to get you the finance you need.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                
        </>
    )
}

export default Offering
