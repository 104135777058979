import TrustBG1 from '../../assets/images/shapes/trust-bg-1-1.png'
import React from 'react'

function Trusted() {
    
    const boxes = [
        { id: 1, text: 'Personable and informative initial consultations' },
        { id: 2, text: 'Secure and privacy consious digital application process' },
        { id: 3, text: 'Evaluation and approvals in as little as 48 business hours!' }
    ];

    return (
        <section className="trusted-company">
            <div className="trusted-company__bg" style={{ backgroundImage: `url(${TrustBG1})` }}></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="trusted-company__content">
                            <div className="block-title text-left">
                                <p>Elite Asset Finance</p>
                                <h2>Experienced and  Respected Team</h2>
                            </div>
                            <div className="trusted-company__image">
                                <img src="assets/images/resources/trust-1-1.png" width="278px" alt="" />
                                <p>Experienced team of seasoned finance brokers dedicated to providing exceptional financial loans.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="trusted-company__box-wrap">
                            {boxes.map(box => (
                                <div className="trusted-company__box" key={box.id}>
                                    <span>0{box.id}</span>
                                    <p>{box.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Trusted
