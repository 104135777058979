import React from 'react'
import { Link}  from 'react-router-dom'
import ApplicationForm from '../../components/hubspotcomponent';

function First() {
    return (
        <>
            <section className="team-details">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                            <div className="block-title text-left">
                                <h2>Elite Asset Finance Terms & Conditions</h2>
                                <h6>Effective: 8th January 2024</h6>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <p className="block-text_legal">These terms and conditions (the "Terms and Conditions") govern the use of
                            eliteassetfinance.com.au (the "Site"). This Site is owned and operated by Elite Asset Finance. This
                            Site is a finance brokerage website.</p>
                            <p className="block-text_legal">By using this Site, you indicate that you have read and understand these Terms and Conditions and
                            agree to abide by them at all times.</p>
                            <h5 className="block-title_legal text-left"><u>Intellectual Property</u></h5>
                            <p className="block-text_legal">All content published and made available on our Site is the property of Elite Asset Finance and the
                            Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable
                            files and anything that contributes to the composition of our Site.</p>
                            <h5 className="block-title_legal text-left"><u>Links to Other Websites</u></h5>
                            <p className="block-text_legal">Our Site contains links to third party websites or services that we do not own or control. We are not
                            responsible for the content, policies, or practices of any third party website or service linked to on
                            our Site. It is your responsibility to read the terms and conditions and privacy policies of these third
                            party websites before using these sites.</p>
                            <h5 className="block-title_legal text-left"><u>Limitation of Liability</u></h5>
                            <p className="block-text_legal">Elite Asset Finance and our directors, officers, agents, employees, subsidiaries, and affiliates will
                            not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees
                            from your use of the Site.</p>
                            <h5 className="block-title_legal text-left"><u>Indemnity</u></h5>
                            <p className="block-text_legal">Except where prohibited by law, by using this Site you indemnify and hold harmless Elite Asset
                            Finance and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions,
                            claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our
                            Site or your violation of these Terms and Conditions.</p>
                            <h5 className="block-title_legal text-left"><u>Applicable Law</u></h5>
                            <p className="block-text_legal">These Terms and Conditions are governed by the laws of the State of Western Australia.</p>
                            <h5 className="block-title_legal text-left"><u>Severability</u></h5>
                            <p className="block-text_legal">If at any time any of the provisions set forth in these Terms and Conditions are found to be
                            inconsistent or invalid under applicable laws, those provisions will be deemed void and will be
                            removed from these Terms and Conditions. All other provisions will not be affected by the removal
                            and the rest of these Terms and Conditions will still be considered valid.</p>
                            <h5 className="block-title_legal text-left"><u>Changes</u></h5>
                            <p className="block-text_legal">These Terms and Conditions may be amended from time to time in order to maintain compliance
                            with the law and to reflect any changes to the way we operate our Site and the way we expect users
                            to behave on our Site. We will notify users by email of changes to these Terms and Conditions or
                            post a notice on our Site.</p>
                            <h5 className="block-title_legal text-left"><u>Contact Details</u></h5>
                            <p className="block-text_legal">Please contact us if you have any questions or concerns. Our contact details are as follows:</p>
                            <p className="block-text_legal">0425 279 148<br></br>admin@eliteassetfinance.com.au<br></br>393 Woollcott Avenue, Brabham, Western Australia, 6055</p>
                            <p className="block-text_legal">You can also contact us through our Contact Form - eliteassetfinance.com.au/contact</p>
                        </div>
                    </div>
                
                </div>
            </section>
        </>
    )
}

export default First