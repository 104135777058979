import React from 'react'
import { Link}  from 'react-router-dom'

function First() {
    return (
        <>
            <section className="team-details">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                            <div className="block-title text-left">
                                <h4>Francois's Financing Solutions Pty Ltd T/as Elite Asset Finance is an authorised Credit Representative (ACR 521178) of Intelligence Pty Ltd (Australian Credit Licence #51183)</h4>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <p className="block-text">This website provides general information only and has been prepared without taking into account your objectives, financial situation or needs. We recommend that you consider whether it is appropriate for your circumstances and your full financial situation will need to be reviewed prior to acceptance of any offer or product. It does not constitute legal, tax or financial advice and you should always seek professional advice in relation to your individual circumstances. Any potential offer of credit is subject to lenders terms and conditions, fees and charges and eligibility criteria. </p>
                        </div>
                    </div>
                
                </div>
            </section>
        </>
    )
}

export default First