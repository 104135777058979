import React from 'react'
import Bradcom from '../../components/Bradcom/Main'
import First from '../Menapplication/First'

const Application = () => {
  return (
    <>
        <Bradcom title={"Home"} subtitle={"Application"} common={"Application"}/>
        <First/>
    </>
  )
}

export default Application