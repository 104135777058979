import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ApplicationForm from '../../hubspotcomponent'

function First() {
    const [emiVal, setEmival] = useState()
    const [TotalVal, setTotalVal] = useState()
    const [slider1, setSlider1] = useState(25000)
    const [slider2, setSlider2] = useState(3)
    const [slider3, setSlider3] = useState(6)

    var startingBalance = slider1,
      totalBalance = startingBalance,
      apr = (slider3 / 100),
      months = (slider2 * 12),
      interest,
      monthlyPayment,
      period;


    function getTotalBalance(balance, apr, months) {
        return balance * Math.pow(1 + (apr / 12), 12 * (months / 12));          
      }
    
      function getMonthlyPayment(balance, apr, months) {
        var r = Math.pow(1 + (apr / 12), (12 / 12)) - 1;
        
        return (balance * ((r * Math.pow(1 + r, months)) / (Math.pow(1 + r, months) - 1))) / 4.33;          
      }
    
      function getPaymentPeriod(balance, apr, payment) {
        var i = 0, fixedPayment = payment.toFixed(2),
          totalBalance;
        
        while (balance > 0 && i < months) {
          totalBalance = balance * Math.pow(1 + (apr / 12), months);
                    
          if (fixedPayment === totalBalance.toFixed(2)) {
            balance -= payment;
          } else {
            balance -= payment - (totalBalance - balance);            
          }
          
          i++;
        }
        
        return i;          
      }
    
      totalBalance = getTotalBalance(startingBalance, apr, months);        
      interest = totalBalance - startingBalance;
      monthlyPayment = getMonthlyPayment(startingBalance, apr, months);
    
      console.log('----------------------------------------------------------');
      console.log('Months: ' + months);        
      console.log('Total Interest Paid: $' + interest.toFixed(2));
      console.log('Total Balance (starting + interest): $' + totalBalance.toFixed(2));
      console.log('Monthly Payment: $' + monthlyPayment.toFixed(2));
      console.log('----------------------------------------------------------');
    
      period = getPaymentPeriod(startingBalance, apr, monthlyPayment);
      totalBalance = getTotalBalance(startingBalance, apr, period);
      interest = totalBalance - startingBalance;
    
      console.log('Total Interest Paid: $' + interest.toFixed(2));
      console.log('Total Balance (starting + interest): $' + totalBalance.toFixed(2));
      console.log('Number of calculated monthly payments: ' + period);

    return (
        <>
            <section className="contact-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                        <p className="block-text">Take the first step and fill in the below information. One of our team will be in touch to learn more about how we can help you get your dream.</p><br />
                        <ApplicationForm 
                                region="eu1"
                                portalId="143255438"
                                formId='a8fcc015-cf22-483b-becf-d18f816f37e2'
                            />
                        </div>
                        <div className="col-lg-6">
                            <form action="#" id="loan-calculator" data-interest-rate="15" className="about-one__form wow fadeInRight" data-wow-duration="1500ms">
                                <h3>Financing Calculator</h3>
                                <div className="about-one__form-content">
                                    <div className="input-box__top">
                                        <span>$15000</span>
                                        <span>$250000</span>
                                    </div>
                                    <div className="input-box">
                                        <div className="range-slider-count" id="range-slider-count">
                                            <input type="range" className="w-100" min="15000" max="250000" value={slider1} onChange={(e) => setSlider1(e.target.value)} />
                                        </div>
                                        <span>{slider1}</span>
                                        <input type="hidden" value="" id="min-value-rangeslider-count" />
                                        <input type="hidden" value="" id="max-value-rangeslider-count" />
                                    </div>
                                    <div className="input-box__top">
                                        <span>1 Year</span>
                                        <span>7 Years</span>
                                    </div>
                                    <div className="input-box">
                                        <div className="range-slider-month" id="range-slider-month">
                                            <input type="range" className="w-100" min="1" max="7" value={slider2} onChange={(e) => setSlider2(e.target.value)} />
                                        </div>
                                        <span>{slider2} Year(s)</span>
                                        <input type="hidden" value="" id="min-value-rangeslider-month" />
                                        <input type="hidden" value="" id="max-value-rangeslider-month" />
                                    </div>
                                    <div className="input-box__top">
                                        <span>3%</span>
                                        <span>15%</span>
                                    </div>
                                    <div className="input-box">
                                        <div className="range-slider-rate" id="range-slider-rate">
                                            <input type="range" className="w-100" min="3" max="15" value={slider3} onChange={(e) => setSlider3(e.target.value)} />
                                        </div>
                                        <span>{slider3}%</span>
                                        <input type="hidden" value="" id="min-value-rangeslider-rate" />
                                        <input type="hidden" value="" id="max-value-rangeslider-rate" />
                                    </div>
                                    <p>
                                        <span>Finance Term</span>
                                        <b><i id="loan-month">{slider2}</i> Year(s)</b>
                                    </p>
                                    <p>
                                        <span>Weekly Payment</span>
                                        <b>$<i id="loan-montlhypayment">{monthlyPayment.toFixed(2)}</i></b>
                                    </p>
                                    
                                    <p>
                                    <span class="calculator-terms_home">**This calculation does not constitute a quote, loan approval, agreement or advice by Elite Asset Finance. The repayment amount shown using this calculator is an estimate, based on information you have provided. It is provided for illustrative purposes only and actual repayment amounts may vary. To find out actual repayment amounts, <a href='/contact'>Contact Us</a>.</span>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First