import SSBAR1 from '../../../assets/images/services/service-sidebar-1-1.jpg'
import { Link }  from 'react-router-dom'
import React, { useState } from 'react'
import ApplicationForm from '../../hubspotcomponent'


function First() {
    const[activeFaq, setActiveFaq] = useState({b: true})
    return (
        <>
            <section className="service-details faq-one">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-8">
                            <div className="service-details__content">
                                <h3>Selling Your Vehicle?</h3>
                                <p> As part of our one stop shop options, we also offer free appraisals on your current vehicle.</p>
                                <p>Whether you're upgrading you're current vehicle, refinancing or simply wanting to sell, Elite Asset Finance offers an in-depth and highly competitive Vehicle Appraisal service.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <img src="https://assets.mysrv.com.au/vehicle-sales.jpg" alt="" className="service-details__content-image img-fluid" />
                                    </div>
                                    <div className="col-xl-6">
                                        <h4>Why Sell With Us</h4>
                                        <p>With a background in dealership sales and purchasing, our team is here to huide you through the entire process.</p>
                                        <ul className="list-unstyled about-two__list service-details__list">
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                Transparent & Genuine
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                Best Price Guarantee
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="">
                            <ApplicationForm 
                            region="eu1"
                            portalId="143255438"
                            formId='89b1708a-4431-45dd-ad76-6b2a6b269df8'
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First