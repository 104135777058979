import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../Menvehiclesales/First'
import Quickly from '../../Menhome/Quickly'

const VehicleSales = () => {

  return (
    <>
        <Bredcom title={"Home"} subtitle={"Services"} common={"Vehicle Sales"}/>
        <First/>
        <Quickly/>
    </>
  )
}

export default VehicleSales