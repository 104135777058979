import { Link, useLocation }  from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import WOW from 'wowjs'

const Navbar = () => {
    const [mobile, setmobile] = useState(false)
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [aboutDrop, setaboutDrop] = useState(false)
    const [servicesDrop, setservicesDrop] = useState(false)
    const [newsDrop, setnewsDrop] = useState(false)
    const [pagesDrop, setpagesDrop] = useState(false)
    const [cardDrop, setcardDrop] = useState(false)
    const [search, setsearch] = useState(false)
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})
    const activeMenu = () => {
        if (path === "/" || path === "/home-02") {
            setmenu({ home: true })
        } else if (path === "/about-us" || path === "/application" || path === "/loan-calculator") {
            setmenu({ about: true })
        } else if (path === "/application" || path === "/application") {
            setmenu({ application: true })
        } else if (path === "/services" || path === "/finance-options" || path === "/vehicle-sales") {
            setmenu({ service: true })
        } else if (path === "/work" || path === "/loan-calculator" || path === "/credit-card" || path === "/faqs" || path === "/testimonial" ) {
            setmenu({ pages: true })
        } else if (path === "/shops" || path === "/shopdetails") {
            setmenu({ shop: true })
        } else if (path === "/news-main" || path === "/news-details") {
            setmenu({ news: true })
        } 
        else if(path === "/contact"){
            setmenu({contact: true})
        }else {
            setmenu({ home: true })
        }
    }

    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
          live: false
        }).init();
        activeMenu()
    }, [path]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }
  return (
    <>
        <div className="page-wrapper">
            <header className="main-header">
                <nav className={`main-menu main-menu__two ${sticky && "stricky-header stricked-menu stricky-fixed"}`}>
                    <div className="container">
                        <div className="logo-box">
                            <Link to="/" aria-label="logo image"><img src="assets/images/logo-dark.png" width="150" alt="Elite Asset Finance Logo"/></Link>
                            <span onClick={() => setmobile(true)} className="fa fa-bars mobile-nav__toggler"></span>
                        </div>
                        <ul className="main-menu__list">
                            <li className={` ${menu.home && "current"}`}>
                                <Link to="/">Home</Link>
                            </li>
                            <li className={`dropdown ${menu.about && "current"}`}>
                                <Link to="/about-us">About</Link>
                                <ul>
                                    <li><Link to="/application">Application</Link></li>
                                    <li><Link to="/loan-calculator">Loan Calculator</Link></li>
                                </ul>
                            </li>
                            <li className={`dropdown ${menu.service && "current"}`}>
                                <Link to="/services">Services</Link>
                                <ul>
                                    <li><Link to="/finance-options">Finance Options</Link></li>
                                    <li><Link to="/vehicle-sales">Sell Your Vehicle</Link></li>
                                </ul>
                            </li>
                            <li className={`dropdown ${menu.contact && "current"}`}><Link to="/contact">Contact</Link></li>
                            
                        </ul>

                        <div className="main-header__info">
                            <div className="main-header__info-phone">
                                <i className="pylon-icon-tech-support"></i>
                                <div className="main-header__info-phone-content">
                                    <span>Give us a call</span>
                                    <h3><a href="tel:+61425279148">0425 279 148</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>

        <div  className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
            <div  className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
            <div  className="mobile-nav__content">
                <span  className="mobile-nav__close mobile-nav__toggler" onClick={() => setmobile(false)}><i  className="far fa-times"></i></span>

                <div  className="logo-box">
                    < Link to="/" aria-label="logo image"><img src="assets/images/logo-light.png" width="150" alt="Elite Asset Finance Logo" /></Link>
                </div>
                <div  className="mobile-nav__container">
                    <ul className="main-menu__list">
                        <li className="current">
                            <Link to="/" onClick={() => setmobile(false)}>Home</Link>
                        </li>
                        
                        <li className="dropdown">
                                <Link to="#" className={aboutDrop ? "expanded" : ""}>About Us
                                    <button aria-label="dropdown toggler" className={aboutDrop ? "expanded" : ""} onClick={() => setaboutDrop(aboutDrop ? false : true)}>
                                        <i className="fa fa-angle-down"></i>
                                    </button>
                                </Link>
                                {aboutDrop &&
                                    <ul style={{ display: "block" }}>
                                        <li><Link to="/about-us" onClick={() => setmobile(false)}>About Us</Link></li>
                                        <li><Link to="/application" onClick={() => setmobile(false)}>Application</Link></li>
                                        <li><Link to="/loan-calculator" onClick={() => setmobile(false)}>Loan Calculator</Link></li>
                                    </ul>}
                            </li>

                        <li className="dropdown">
                            <Link to=""  className={servicesDrop ? "expanded" : ""}>Services
                                <button aria-label="dropdown" className={servicesDrop ? "expanded" : ""} onClick={() => setservicesDrop(servicesDrop ? false : true)}>
                                    <i className="fa fa-angle-down"></i>
                                </button>
                            </Link>
                            {servicesDrop &&
                                <ul style={{ display: "block" }}>
                                    <li><Link to="/services" onClick={() => setmobile(false)}>Our Services</Link></li>
                                    <li><Link to="/finance-options" onClick={() => setmobile(false)}>Finance Options</Link></li>
                                    <li><Link to="/vehicle-sales" onClick={() => setmobile(false)}>Sell Your Vehicle</Link></li>
                                </ul>}
                        </li>
                        
                        
                        <li>
                            <Link to="/contact" onClick={() => setmobile(false)}>Contact</Link>
                        </li>
                    </ul>
                </div>

                <ul  className="mobile-nav__contact list-unstyled">
                    <li>
                        <i  className="pylon-icon-email1"></i>
                        <a href="mailto:admin@eliteassetfinance.com.au">Email Us</a>
                    </li>
                    <li>
                        <i  className="pylon-icon-telephone"></i>
                        <a href="tel:+61425279148">0425 279 148</a>
                    </li>
                </ul>
                <div  className="mobile-nav__top">
                    <div  className="mobile-nav__social">
                        < Link to="#" aria-label="facebook"><i  className="fab fa-facebook-square"></i></Link>
                        < Link to="#" aria-label="instagram"><i  className="fab fa-instagram"></i></Link>
                        < Link to="#" aria-label="linkedin"><i  className="fab fa-linkedin"></i></Link>
                        < a href="https://www.google.com/maps/place/Elite+Asset+Finance/@-30.3639909,114.78411,4z/data=!3m1!4b1!4m12!1m5!8m4!1e1!2s112671428170000107719!3m1!1e1!3m5!1s0xa4de70069c676e11:0x788dc874fb87e96!8m2!3d-32.205415!4d136.1073692!16s%2Fg%2F11krk0q2kk?hl=en-US&entry=ttu" aria-label="google"><i  className="fab fa-google"></i></a>
                    </div>
                </div>
            </div>
        </div>
    
    </>
  )
}

export default Navbar