import React from 'react'
import { Link}  from 'react-router-dom'

<script charset="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js"></script>

function First() {
    return (
        <>
            <section className="team-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="assets/images/team/brianf.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="team-details__content">
                                <h2>Brian Francois</h2>
                                <span>Director & Head of Finance</span>
                                <p>Starting out as a trainee a decade ago, Brian quickly excelled through his role as Senior Finance manager with one of Australia’s largest Automotive companies and was nominated many awards throughout the years. He focused his expertise and passion in Finance while continuously educating himself on the current changes throughout the finance industry and even spent 4 years being a mortgage broker.  His strict morals and dedication to his clients has led him to running his own brokerage and achieving the best outcomes for his clients.</p>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Financial Experience</h3>
                                        <span>10 Years</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '100%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Loans Approved</h3>
                                        <span>2500+</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '100%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="team-details__social">
                                    <Link to="//https://m.facebook.com/brianfrancois7/" className="fab fa-facebook-square"></Link>
                                    <Link to="//https://www.instagram.com/brian__francois/?hl=en" className="fab fa-instagram"></Link>
                                    <Link to="//https://www.google.com/maps/place/Elite+Asset+Finance/@-32.205415,136.1073692,4z/data=!4m2!3m1!1s0x0:0x788dc874fb87e96?sa=X&ved=2ahUKEwiFzLq-7YeBAxULwzgGHcNnDzIQ_BJ6BAhQEAA&ved=2ahUKEwiFzLq-7YeBAxULwzgGHcNnDzIQ_BJ6BAhWEAg" className="fab fa-google"></Link>
                                    <Link to="//https://au.linkedin.com/in/brian-francois-b1485785" className="fab fa-linkedin"></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-6">
                            <div className="team-details__content">
                                <h2>Russell Court</h2>
                                <span>Broker & Sales Manager</span>
                                <p>From selling cars to becoming a sales manager, Russell climbed up the ranks very quickly due to his exceptional service he gave his clients & his knowledge in the Automotive industry. Now, entering the world of Brokering, he aims to provide his clients with nothing short of excellence.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src="assets/images/team/russelc.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                
                </div>
            </section>
        </>
    )
}

export default First