import React from 'react'
import { Link}  from 'react-router-dom'
import ApplicationForm from '../../components/hubspotcomponent';

function First() {
    return (
        <>
            <section className="team-details">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-6">
                            <div className="block-title text-left">
                                <h2>Flexible and Quick Financing</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="block-text">Complete the Application form below to take the first step in obtaining financing! Our team will assess the information within this form to better understand your situation and possible borrowing power and will be in touch with you shortly after to get you closer to your dreams!</p>
                        </div>
                        <div className="col-lg-12">
                            <ApplicationForm 
                            region="eu1"
                            portalId="143255438"
                            formId='69630c3a-496d-40c9-b594-37f285a41b5a'
                            />
                        </div>
                    </div>
                
                </div>
            </section>
        </>
    )
}

export default First