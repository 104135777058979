import BGPH1 from '../../assets/images/main-slider/hero-image.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link }  from 'react-router-dom'
import React  from 'react'

function Sliderone() {
    return (
        <>
            <section className="main-slider">
                <Swiper className="swiper-container thm-swiper__slider"
                >
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-8 col-lg-8">
                                            <h3 style={{color: '#57743D'}}>Elite Asset Finance</h3>
                                            <h4>One-Stop Shop For All Your Financial Needs</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </div>

                </Swiper>
                <div className="feature-two">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms">
                                <div className="feature-two__box">
                                    <i className="pylon-icon-consumer-behavior"></i>
                                    <p>Fast Loan Process</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms">
                                <div className="feature-two__box">
                                    <i className="pylon-icon-point-of-sale"></i>
                                    <p>Flexible Repayments</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sliderone