import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../Menpersonalloans/First'
import Quickly from '../../Menhome/Quickly'

const PersonalFinance = () => {

  return (
    <>
        <Bredcom title={"Home"} subtitle={"Services"} common={"Personal Finance"}/>
        <First/>
        <Quickly/>
    </>
  )
}

export default PersonalFinance