import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Navbar from '../components/Navbar/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import Team from '../components/About/Team/Main'
import TeamDetails from '../components/About/TeamDetails/Main'
import Service from '../components/Services/Service/Main'
import ServiceDetails from '../components/Services/ServiceDetails/Main'
import VehicleSales from '../components/Services/VehicleSales/Main'
import PersonalFinance from '../components/Services/PersonalFinance/Main'
import NewsMain from '../components/News/NewsMain/Main'
import NewsDetails from '../components/News/NewsDetails/Main'
import Work from '../components/Pages/Work/Main'
import LoanCalculator from '../components/Pages/LoanCalculator/Main'
import FAQ from '../components/Pages/FAQ/Main'
import Testimonials from '../components/Pages/Testimonial/Main'
import Contact from '../components/Contact/Main'
import Application from '../components/Apply/Main'
import TermsConditions from '../components/Terms/Main'
import PrivacyPolicy from '../components/Privacy/Main'
import Disclaimer from '../components/Disclaimer/Main'
import Sitemap from '../components/Sitemap/Main'

const Routing = () => {
    
    return (
        <>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/team" element={<Team />} />
                <Route exact path="/about-us" element={<TeamDetails />} />
                <Route exact path="/services" element={<Service />} />
                <Route exact path="/finance-options" element={<ServiceDetails />} />
                <Route exact path="/news-main" element={<NewsMain />} />
                <Route exact path="/news-details" element={<NewsDetails />} />
                <Route exact path="/work" element={<Work />} />
                <Route exact path="/loan-calculator" element={< LoanCalculator />} />
                <Route exact path="/faqs" element={<FAQ />} />
                <Route exact path="/testimonial" element={<Testimonials />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/personal-finance" element={<PersonalFinance />} />
                <Route exact path="/application" element={<Application />} />
                <Route exact path="/vehicle-sales" element={<VehicleSales />} />
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="/terms-conditions" element={<TermsConditions />} />
                <Route exact path="/disclaimer" element={<Disclaimer />} />
                <Route exact path="/sitemap" element={<Sitemap />} />
                <Route exact path='*'>404 Not Found</Route>
            </Routes>
            <Footer />
        </>
    )
}

export default Routing