import React from 'react'

function Why() {
    return (
        <>
            <section className="why-choose" id="aboutus">
                <img src="assets/images/shapes/why-choose-shape-1-1.png" className="why-choose__shape-1" alt=""/>
                <img src="assets/images/shapes/why-choose-shape-1-2.png" className="why-choose__shape-2" alt="" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="why-choose__image">
                                <p><i className="pylon-icon-investment"></i>15+ years of working experience</p>
                                <img src="assets/images/resources/why-choose-1-1.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="why-choose__content">
                                <div className="block-title text-left">
                                    <p>Our Elite Benfits</p>
                                    <h2>Why Choose Us?</h2>
                                </div>
                                <p>Experienced Finance Broker with a demonstrated history of working in the financial services industry.</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="why-choose__box">
                                            <h3><i className="fa fa-caret-right"></i>Professional Team</h3>
                                            <p>A dedicated team of finance professionals making your dreams come true!</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="why-choose__box">
                                            <h3><i className="fa fa-caret-right"></i>Fast Approvals</h3>
                                            <p>Financing in as little as 48 business hours from application and document submissions.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Why